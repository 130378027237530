import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Layout from "../components/common/Layout";
import SEO from "../components/common/Seo";
import tw from "twin.macro";
import { DiAndroid, DiApple } from 'react-icons/di';

const IndexPage = () => {
  const intl = useIntl();

  const buttonStyle = tw`rounded-md mb-5 px-5 py-3 lg:mb-0 lg:rounded-lg shadow-lg lg:px-10 lg:py-5 bg-primary text-gray-100 duration-300 hover:bg-secondary font-montserrat uppercase items-center flex`;
  const h1Style = tw`text-gray-50 font-montserrat text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold uppercase lg:leading-tight opacity-90 text-left`;
  const h2Style = tw`text-gray-50 font-montserrat text-lg leading-tight opacity-90 mt-5 text-justify sm:text-xl md:text-xl lg:text-3xl lg:leading-10`;

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "2" })}
        description={intl.formatMessage({ id: "5" })}
      />

      <div css={[tw`max-w-xs px-3 sm:px-0 sm:max-w-xl lg:max-w-3xl xl:max-w-4xl`]}>
        <h1 css={[h1Style]}>{intl.formatMessage({ id: "1" })}</h1>
        <h2 css={[h2Style]}>{intl.formatMessage({ id: "5" })}</h2>

        {/** Contenedor botones Android / iOS */}
        <div css={[tw`lg:flex lg:justify-between lg:items-center mt-5 lg:mt-10 lg:space-x-10`]}>
          <a
            aria-label={intl.formatMessage({ id: "6" })}
            href="https://play.google.com/store/apps/details?id=com.moybu.apps.bdtmm"
            rel="noreferrer"
            target="_blank"
            css={[buttonStyle]}
          >
            <DiAndroid css={[tw`w-12 h-12 lg:h-8 lg:w-8 lg:inline mr-5`]} />
            <span css={[tw`opacity-90 text-sm lg:text-lg`]}>{intl.formatMessage({ id: "6" })}</span>
          </a>

          <a
            aria-label={intl.formatMessage({ id: "7" })}
            href="https://apps.apple.com/es/app/banc-del-temps-mm/id1516522845"
            rel="noreferrer"
            target="_blank"
            css={[buttonStyle]}
          >
            <DiApple css={[tw`w-12 h-12 lg:h-8 lg:w-8 lg:inline mr-5`]} />
            <span css={[tw`opacity-90 text-sm lg:text-lg`]}>{intl.formatMessage({ id: "7" })}</span>
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
